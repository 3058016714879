import ForYou from 'views/ForYou';
import Welcome from 'views/Welcome';
import { Component } from 'react';

type Props = {
  isAnonymous: boolean;
  path: string;
  searchQueryFromQS: string;
  openSearch: () => void;
  setSearchQuery: (searchQuery: { query: string }) => void;
};

class HomeContainer extends Component<Props> {
  componentDidMount() {
    const { path, setSearchQuery, searchQueryFromQS, openSearch } = this.props;
    if (path === '/search/') {
      setSearchQuery({ query: searchQueryFromQS });
      openSearch();
    }
  }

  render() {
    const { isAnonymous } = this.props;
    if (isAnonymous) {
      return <Welcome />;
    }
    return <ForYou />;
  }
}

export default HomeContainer;
